import ImageBanner from '@/components/ui/organisms/image-banner/image-banner'
import {
	Subtitle as SubtitleBase,
	Title as TitleBase,
} from '@/components/ui/organisms/image-banner/image-banner.styled'
import styled from '@emotion/styled'

export const ImageBannerStyled = styled(ImageBanner)`
	border-radius: calc(var(--card-border-radius) * 2) !important;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: ${({ theme }) => theme.spacing(2, 2)};
	height: ${({ theme }) => `calc(100% - ${theme.spacing(5)})`};
	> * {
		width: 100%;
		flex-basis: 100%;
	}
	.img {
		margin: ${({ theme }) => theme.spacing('auto', 'auto', 2)} !important;
		height: fit-content;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		.img {
			margin: ${({ theme }) => theme.spacing('auto', 'auto', 3)} !important;
		}
	}
	img {
		width: 100% !important;
		height: 100% !important;
		min-height: auto !important;
	}
`

export const Title = styled(TitleBase)`
	position: initial;
	transform: none;
	color: ${({ theme }) => theme.palette.primary.main};
	text-transform: none;
	font-family: var(--font-family-oracle-regular);
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`

export const Subtitle = styled(SubtitleBase)``
