import { cx } from '@emotion/css'
import ImageBanner from '../image-banner/image-banner'
import { ImageBannerStyled, Subtitle, Title } from './icon-text-banner.styled'

const IconTextBanner = ({ className, ...props }) => {
	return (
		<ImageBannerStyled
			className={cx('IconTextBanner-root', className)}
			{...props}
			TitleComponent={Title}
			SubtitleComponent={Subtitle}
		/>
	)
}

IconTextBanner.defaultProps = {
	...ImageBanner.defaultProps,
}

IconTextBanner.propTypes = {
	...ImageBanner.propTypes,
}

export default IconTextBanner
